.location-button {
  width: auto;
  height: 40px; 
  min-width: 150px; 
  text-align: center; 
  display: flex;
  justify-content: center;
  align-items: center;
}

@keyframes wave {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.dots-container {
  display: inline-block;
  font-size: 1.5rem; 
  color: #fff; 
}

.dot {
  display: inline-block;
  opacity: 0;
  height: 1rem;
  width: 1rem;
  margin-left: 0.2rem;
  border-radius: 50%;
  animation: wave 1.4s infinite ease-in-out;
}

.dot:nth-child(1) {
  animation-delay: 0s;
}

.dot:nth-child(2) {
  animation-delay: 0.2s;
}

.dot:nth-child(3) {
  animation-delay: 0.4s;
}
