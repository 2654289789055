/* Add this CSS to your styles (for example, in your main stylesheet or in a CSS module) */
@keyframes wave {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  
  .dots-container {
    display: inline-block;
    font-size: 1.2rem;
    color: #fff; /* You can change this color */
  }
  
  .dot {
    display: inline-block;
    /* margin-right: 0.1rem; */
    opacity: 0;
    height: 1rem;
    width: 1rem;
    border-radius: 10px;
    animation: wave 1.4s infinite ease-in-out;
  }
  
  .dot:nth-child(1) {
    animation-delay: 0s;
  }
  
  .dot:nth-child(2) {
    animation-delay: 0.2s;
  }
  
  .dot:nth-child(3) {
    animation-delay: 0.4s;
  }
  