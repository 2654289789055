.sliding-container {
  width: 100%;
  overflow: hidden;
  background-color: black;
}

.sliding-text {
  display: inline-block;
  white-space: nowrap;
  animation: slide 50s linear infinite;
  font-family: "Arial", sans-serif;
  font-size: 1rem;
  color: white;
  padding: 15px;
}

@keyframes slide {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}

@media (max-width: 768px) {
  .sliding-text {
    font-size: 1rem;
  }
}
@media (max-width: 480px) {
  .sliding-text {
    font-size: 0.9rem;
  }
}
