/* Hide horizontal scrollbar */
.hidden-scrollbar {
  -ms-overflow-style: none; /* for Internet Explorer 10+ */
  scrollbar-width: none; /* for Firefox */
}

/* Apply smooth scrolling */
.scroll-smooth {
  scroll-behavior: smooth;
}

/* Add custom animations for vertical borders */
.animate-border {
  border-left: 4px solid transparent;
  transition: border-color 0.3s ease-in-out;
}


.wishlist-button {
  outline: none;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.wishlist-button .icon {
  transition: transform 0.3s ease;
}

.wishlist-button:hover .icon {
  transform: scale(1.1);
}
