/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
} */

/* @font-face {
  font-family: "Outer Sans Alt Light";
  src: url("/src//Assets/Font/made_outer_sans/MADE Outer Sans Alt Light PERSONAL USE.otf")
    format("truetype");
  font-weight: normal;
  font-style: normal;
} */

/* @font-face {
  font-family: "Futura Regular";
  src: url("/src//Assets/Font/futura/FUTURA55REGULAR.TTF") format("truetype");
  font-weight: normal;
  font-style: normal;
} */
html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
}

/* Main container to stretch and place footer at the bottom */
.main-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh; /* Ensure full viewport height */
}

/* Content should take up all available space */
.content {
  flex: 1;
}
@font-face {
  font-family: "Futura Medium";
  src: url("/src//Assets/Font/futura/FUTURA65MEDIUM.TTF") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Futura Regular";
  src: url("/src//Assets/Font/futura/FUTURA55REGULAR.TTF") format("truetype");
  font-weight: normal;
  font-style: normal;
}
/* Default font for body */
body {
  margin: 0;
  font-family: "Futura Regular", sans-serif;
}

/* Optional: Styling for title */
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Outer Sans Alt Light", sans-serif;
}

@tailwind base;
@tailwind components;
@tailwind utilities;
